@use "sass:string";
@use 'style-guide/components/variables';
@use 'style-guide/components/color';
@use 'style-guide/components/typography';
@forward 'style-guide/components/variables';

/*TODO move this to the style guide*/
/* For the sticky footer */
html,
body {
  height: 100%;
  background: #fff;
  scroll-behavior: smooth;
}

html.no-doc-scroll {
  overflow: hidden;
}

span {
  font-size: inherit;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.pa-none {
  padding: 0;
}

.page-content-wrapper {
  min-height: 100%;
}

.ie8 .page-content-wrapper {
  padding-bottom: 0px;
}

.ie8 .hidden-ie8 {
  display: none !important;
}

.ie8 .search-results-logo {
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}

.footer {
  position: relative;
  clear: both;
}

/* So things look pretty */
.distance-facet-input {
  width: 25px;
}

.no-wrap {
  white-space: nowrap;
}

.search-container {
  position: relative;
}

div.pma_link {
  padding-bottom: 15px;
  text-align: right;
}

div.modal {
  width: 100% !important;
  background-color: initial;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  position: fixed;
  white-space: nowrap; //ie8
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: 0px;

  .modal-dialog {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }

  .modal-content {
    position: absolute;
  }

  @media (min-width: 420px) {
    .modal-dialog {
      width: 420px;
    }
  }

  @media (max-width: 992px) {
    white-space: normal;
  }

  @media (max-width: 767px) {
    .modal-dialog {
      margin-top: 0px !important;
    }
  }
}

#modal-header {
  white-space: normal;

  .close span {
    font-size: 28px;
    font-weight: 400;
  }

  @media (max-width: 992px) {
    padding: 15px;
  }
}

.close-icon {
  color: variables.$gray-1;
  font-size: 26px;
}

.make-appt-box {
  flex: 1 1 0px;
  padding: 20px;
  display: inline-block;
  &:not(:last-child) {
    margin-right: 15px;
  }
}

.modal-icon {
  font-size: 60px;
  color: variables.$gray-1;
  display: inline-block;
  padding-bottom: 25px;
}

.modal-icon-container {
  padding-top: 15px;
}

.modal-text {
  line-height: variables.$line-height-base;
  min-height: (2 * variables.$line-height-base) + string.unquote('em'); // ensures alignment between boxes
  padding-bottom: 30px;
  text-align: center;
  white-space: normal;
}

.modal-phone-text {
  height: 36px;
  line-height: 36px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.modal-phone-text.multi-line {
  line-height: 1;

  span {
    font-size: inherit;
  }
}

.phone-mobile {
  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.phone-desktop {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

// Shifts tooltip on distance facet to the left
#locationfilter + div.tooltip.left {
  margin-left: -20px;
}

ul.subspecialty-list {
  list-style-position: outside;
}

#clear-location-btn {
  margin-top: 42px;
  font-size: 25px;
  color: #748496;
  position: absolute;
  right: 85px;
  top: 0px;
}

#clear-location-btn:hover {
  text-decoration: none;
}

.filter-group .icon-event-available {
  &:before {
    vertical-align: top;
  }
}

.filter-group > label {
  text-indent: 0;
}

.filter-group label span {
  white-space: inherit;
}

.filter-group {
  border: 1px solid #d4d8dc;
  padding: 16px 0 36px;
  border-radius: 4px;
  position: relative;

  > div:not(:first-child) {
    color: #2a3d51;
  }

  .filter-box {
    &.multi {
      border: none;
      ul.no-bullets {
        background: none;

        li {
          border: none;
          padding: 0 16px;

          > label {
            input[type='checkbox'],
            input[type='radio'] {
              margin-right: 8px;
              position: relative;
              top: 2px;
            }

            #book-online-facet-icon {
              position: relative;
              top: -1px;
            }
          }

          span {
            &.count {
              color: #2a3d51;
            }
          }

          &:hover {
            background: #f5f5f5;
          }
        }
      }

      .show-more {
        text-align: left;
        padding: 8px 16px 0;
        position: absolute;
        font-size: 14px;
        left: 4px;
      }
    }

    &#locationfilter {
      background: none;
      border: none;
      padding: 8px 16px 0;
    }
  }
}

.location-input {
  display: table;

  input {
    width: 100%;
    line-height: 20px;
    display: table-cell;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  a.btn-m {
    display: table-cell;
    width: 1%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

div.readonly-searchable-location-input {
  display: flex;
  justify-content: space-between;

  button.location-inputbutton {
    border-radius: 0;
  }
}

a.powered-by-link,
a:hover.powered-by-link {
  text-decoration: none;
}

.consumer-facets-mobile,
h2.consumer-facets-mobile {
  display: none;
}

.powered-by-link {
  overflow: hidden;
}

.search-bar-row {
  padding: 0 15px;
}

@media (max-width: 768px) {
  .search-bar-row .ky-search .ky-search-bar {
    label {
      font-size: 16px;
    }

    input {
      &[type='text'] {
        font-size: 16px;
        padding: 8px;
      }
    }
  }

  .search-container .row {
    margin-left: 0;
    margin-right: 0;
  }
}

img.provider-image-lg {
  max-width: auto;
  max-height: auto;
  width: 130px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

$customer-logo-height: 50px;
$customer-logo-width: 110px;

img.affiliation-logo {
  max-height: $customer-logo-height;
  max-width: $customer-logo-width;
  width: auto;
}

img.search-results-logo {
  max-height: $customer-logo-height;
  max-width: $customer-logo-width;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 8px;
}

.about-panel-row {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
}

.about-panel-item {
  white-space: pre-wrap;
}

div.about-panel-item.has-logo-image {
  padding-bottom: 0;
  line-height: 2;
  margin-bottom: 15px;
}

a.about-panel-item.has-logo-image {
  box-sizing: border-box;
  padding-bottom: 0;
  line-height: 2;
  margin-bottom: 15px;
}

address {
  font-style: normal;
  line-height: 1.5em;
}

.typeahead-filter {
  margin-top: variables.$sp-s;
  border-radius: variables.$br-m;
}

#languages-language {
  padding: 10px 16px;
}

@mixin horizontal-ruler($percentage) {
  &:before {
    width: $percentage;
    margin-left: (100% - $percentage) * 0.5;
    margin-right: (100% - $percentage) * 0.5;
    padding-top: 20px;
    border-top: 1px solid variables.$gray-4;
  }
  &:first-child:before {
    border-top: none;
    padding-top: 0;
  }
}

.horizontal-rule {
  @include horizontal-ruler(90%);
}

.horizontal-rule-wide {
  @include horizontal-ruler(96%);
  @media (max-width: 767px) {
    @include horizontal-ruler(90%);
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-wrapper-for-overlay {
  float: left;
  position: relative;
}

.has-provider-video {
  position: absolute;
  bottom: 2px;
  left: 0;
  text-align: center;
  font-size: variables.$fs-xs;
  color: variables.$white;
  background: variables.$ui-link;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 100%;
  opacity: 0.8;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  span {
    vertical-align: middle;
    margin-left: 3px;
  }
}

.stars {
  display: inline-block;
  width: 5.75em;
  height: 1em;
  letter-spacing: 0.25em;

  div {
    font-size: inherit;
  }

  [class^='icon-'] {
    font-size: inherit;
    color: variables.$ui-warning;
  }

  .star-wrapper {
    position: relative;
    width: 100%;
  }

  .filled,
  .empty {
    position: absolute;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
}

.summary-stars .stars {
  margin-right: variables.$sp-xs;
  font-size: variables.$fs-m;
  line-height: variables.$line-height-base;
}

.summary-stars {
  line-height: variables.$line-height-base;
}

.share-bar a {
  box-sizing: border-box;
}

.ratings {
  padding-bottom: variables.$sp-l;

  .ratings-title {
    border-bottom: 1px solid variables.$gray-4;
    margin-left: variables.$sp-xs;
    margin-right: variables.$sp-xs;
    margin-bottom: variables.$sp-l;

    .stars {
      font-size: variables.$fs-l;
    }

    .star-wrapper {
      margin-top: variables.$sp-xs;
    }

    > div {
      padding-bottom: variables.$sp-s;
    }
  }

  .stars {
    margin-right: variables.$sp-m;
  }

  .review-count {
    font-size: variables.$fs-s;
    color: variables.$gray-2;
    margin-top: variables.$sp-xs;
  }

  .ratings-column {
    font-weight: variables.$fw-5;
    font-size: variables.$fs-m;
    border-right: 1px solid variables.$gray-4;
    padding-bottom: variables.$sp-s;
    padding-right: variables.$sp-l;
    text-align: right;
  }
  .star-column {
    border-left: 1px solid variables.$gray-4;
    padding-bottom: variables.$sp-s;
    padding-left: variables.$sp-l;
    margin-left: -1px;
  }

  .metric,
  .review-count {
    line-height: variables.$line-height-base;
    display: inline-block;
  }

  .average_rating {
    text-align: right;
  }
}

.review {
  .review-date {
    color: variables.$gray-2;
  }

  .review-question {
    font-weight: variables.$fw-6;
    margin-bottom: variables.$sp-xs;
  }

  .review-question-response {
    margin-bottom: variables.$sp-m;
  }
}

.review-disclaimer-text {
  font-size: variables.$fs-xs;
  color: variables.$gray-2;
  a {
    font-size: inherit;
  }
}

.show-more {
  .show-more-hidden {
    display: none;
  }

  .show-more-button,
  .button.show-more-button {
    color: variables.$gray-2;
    text-align: center;
    padding-top: variables.$sp-s;
    padding-bottom: variables.$sp-s;
    text-decoration: none;
    border-top: 1px solid variables.$gray-4;
    width: 100%;

    &:hover {
      color: variables.$gray-1;
    }
  }

  &.show-more-expanded {
    .show-more-hidden {
      display: block;
    }

    .show-more-button {
      display: none;
    }
  }
}

.experience-field-title {
  font-weight: variables.$fw-5;
  font-size: variables.$fs-m;
  padding-top: 20px;
  padding-bottom: 20px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0.4;
  z-index: variables.$zindex-popover; //1060

  &.filter-drawer-open {
    background-color: transparent;
    opacity: 1;
  }

  .spinner-container {
    position: fixed;
    left: 50%;
    top: 50%;
  }
}

#map-canvas:not(.no-display) {
  border-bottom: 1px solid variables.$gray-4;
  height: 215px;
  background-color: variables.$gray-4;
}

span.sort-menu-item {
  &:before {
    content: ' | ';
  }

  &:nth-child(2):before {
    //The screen reader content makes the first sort item the second child
    content: '';
  }
}

.filter-search-mobile {
  @media only screen and (max-width: 767px) {
    padding: 0 16px;
  }
}

@media (max-width: 992px) {
  .consumer-facets-mobile,
  h2.consumer-facets-mobile {
    display: block;
  }

  .facets-back {
    background-color: variables.$gray-5;
    margin-bottom: 10px;
    min-height: 55px;
    line-height: 55px;

    // Add margin to all children
    & > * {
      margin-right: 10px;
    }
  }

  body.facets-open {
    overflow: hidden;
  }

  .loading-overlay {
    position: fixed;
  }

  .appt-request {
    max-width: 460px;
  }

  .make-appt-box {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
    height: auto;
    padding: 15px;

    &:not(:last-child) {
      margin-right: 0;
    }
  }

  .modal-message {
    position: relative;
  }

  .modal-icon-container {
    display: inline;
    float: left;
    padding-top: 0;
    padding-bottom: 15px;
    padding-right: 15px;
  }

  .modal-icon {
    font-size: 40px;
    color: variables.$gray-1;
    padding-bottom: 0;
  }

  .modal-text {
    text-align: left;
    padding-bottom: 15px;
    margin-left: 0;
    overflow: hidden;
    height: 40px;
    display: table-cell;
    vertical-align: middle;
  }
}

@media (max-width: 767px) {
  img.provider-image {
    max-width: 100%;
    box-sizing: border-box;
  }

  .pagination-col {
    text-align: center;
  }

  .pagination {
    display: inline-block;
  }

  .icon-powered-by-logo:before {
    overflow: hidden;
  }

  .has-provider-video {
    max-width: 100%;
  }

  .ratings .metric,
  .ratings .review-count {
    display: block;
  }

  .sort-dropdown {
    width: 100%;
  }
}

button.button.btn-anchor {
  color: variables.$ui-link;
  background: transparent;
  border-radius: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:focus {
    outline: 1px solid variables.$ui-link;
    outline: -webkit-focus-ring-color auto 5px;
  }

  &.dark-link {
    @extend .fc-gray-1;
  }
}

.child-fs-xs * {
  @extend .fs-xs;
}

ul.two-col-horizontal {
  li:nth-child(2n + 1) {
    clear: left;
  }

  li {
    float: left;
    width: 50%;
    box-sizing: border-box;
  }

  &.list-m li {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.direct-book-container, .manage-appointment-container {
  max-width: 1440px;
  margin: 0 auto;
}

#hide-filters-mobile {
  padding: 25px 35px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.search-container .result-column table thead td {
  border-top: none;
  border-bottom: 1px solid #9aa5b2;
}
